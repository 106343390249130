<template>
  <div class="wrapper register-wrapper">
    <div class="container">
      <div
        class="flex flex-col w-6/12 mobile:w-11/12 medium:w-9/12 mx-auto rounded-md h-auto"
      >
        <div
          class="bg-grey bg-opacity-90 text-white rounded-md p-8 shadow-lg my-24 items-center flex flex-col"
        >
          <h2 class="mb-5">Aktiveeri oma Gewodo konto</h2>
          <div class="error-box w-full mb-3 flex-col" v-if="$v.$error || error">
            <span v-if="!$v.form.email.required"
              >Palun sisestage meilikonto aadress</span
            >
            <span v-if="!$v.form.email.email"
              >Meilikonto ei vasta nõuetele</span
            >
            <span v-if="!$v.form.password.required"
              >Palun sisestage salasõna</span
            >
            <span v-if="!$v.form.password.minLength"
              >Salasõna peab olema vähemalt 8 tähemärki pikk</span
            >
            <span v-if="!$v.form.confirmPassword.required"
              >Palun kinnitage sisestatud salasõna</span
            >
            <span v-if="!$v.form.confirmPassword.sameAsPassword"
              >Salasõnad ei kattu</span
            >
            <span v-if="error">{{ errorText }}</span>
          </div>
          <div
            class="success-box w-full mb-3 flex-col items-center"
            v-if="success"
          >
            <h2>Täname, et aktiveerisite konto!</h2>
            <p>
              Palun logige sisse ning vaadake üle enda profiiliinfo ja täitke
              teenuste pakkumiseks vajalikud väljad.
            </p>
          </div>
          <div class="py-12" v-if="success">
            <button
              class="py-3 px-8 text-2xl new-button-green label"
              @click="$router.push('/login')"
            >
              Logi sisse
            </button>
          </div>
          <div class="form-group mb-5 w-full" v-if="!success">
            <h4>Kinnita meilikonto</h4>
            <input
              type="email"
              class="bg-offwhite shadow-lg"
              v-model="form.email"
            />
            <span class="bg-offwhite rounded-md text-black mt-2 p-2 shadow-lg"
              ><span class="text-danger font-bold">NB!</span> Palun kasutage
              emaili, millele aktiveerimislingiga kiri saabus.</span
            >
          </div>
          <div class="form-group mb-5 w-full" v-if="!success">
            <h4>Vali salasõna</h4>
            <input
              type="password"
              class="bg-offwhite shadow-lg"
              v-model="form.password"
            />
          </div>
          <div class="form-group mb-5 w-full" v-if="!success">
            <h4>Kinnita salasõna</h4>
            <input
              type="password"
              class="bg-offwhite shadow-lg"
              v-model="form.confirmPassword"
            />
          </div>
          <button
            @click="confirmUser"
            v-if="!success"
            class="new-button-green label"
          >
            Kinnita
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength, sameAs, email } from "vuelidate/lib/validators";
import RequestHandler from "@/assets/mixins/RequestHandler";
export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
        confirmPassword: ""
      },
      success: false,
      error: false,
      errorText: ""
    };
  },
  mixins: [RequestHandler],
  methods: {
    confirmUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        const formData = {
          email: this.form.email.toLowerCase(),
          password: this.form.password
        };
        this.apiRequest(
          "authentication/user/service_provider/" +
            this.$route.params.token +
            "/",
          "post",
          false,
          formData
        )
          .then(
            res => {
              if (res.status === 200) {
                this.success = true;
              } else {
                if (res.status === 409) {
                  this.errorText = "Vorm täitmata";
                } else if (res.status === 405) {
                  this.errorText = "Selle konto jaoks pole aktiveerimislinki";
                } else if (res.status === 403) {
                  this.errorText = "Vale meiliaadress konto aktiveerimiseks";
                } else if (res.status === 400) {
                  this.errorText = "Salasõna ei vasta nõuetele!";
                } else {
                  this.errorText =
                    "Midagi läks valest! Kontrollige oma infot ja proovige uuesti.";
                }
                this.error = true;
              }
            },
            err => {
              let errObj = { err };
              if (errObj.err.response.status === 409) {
                this.errorText = "Vorm täitmata";
              } else if (errObj.err.response.status === 405) {
                this.errorText = "Selle konto jaoks pole aktiveerimislinki";
              } else if (errObj.err.response.status === 403) {
                this.errorText = "Vale meiliaadress konto aktiveerimiseks";
              } else if (errObj.err.response.status === 400) {
                this.errorText = "Salasõna ei vasta nõuetele!";
              } else {
                this.errorText =
                  "Midagi läks valest! Kontrollige oma infot ja proovige uuesti.";
              }
              this.error = true;
            }
          )
          .catch(err => {
            let errObj = { err };
            if (errObj.err.response.status === 409) {
              this.errorText = "Vorm täitmata";
            } else if (errObj.err.response.status === 405) {
              this.errorText = "Selle konto jaoks pole aktiveerimislinki";
            } else if (errObj.err.response.status === 403) {
              this.errorText = "Vale meiliaadress konto aktiveerimiseks";
            } else if (errObj.err.response.status === 400) {
              this.errorText = "Salasõna ei vasta nõuetele!";
            } else {
              this.errorText =
                "Midagi läks valest! Kontrollige oma infot ja proovige uuesti.";
            }

            this.error = true;
          });
      }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8)
      },
      confirmPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  }
};
</script>
